import NavigableModal, {
	MODAL_TYPES,
} from '@components/NavigableModal/NavigableModal';
import { useScreenDetails } from '@hooks/useScreenDetails';
import { ModalPortal } from '@xo-union/ui-accessibility';
import { useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import { FCWithChildren } from 'types/react-extended';
import {
	closeReviewsLightboxAtom,
	isReviewsLightboxOpenAtom,
} from '../../atoms';
import { Landscape } from './landscape';
import { Portrait } from './portrait';
import Styles from './styles.scss';

export interface ModalWrapperProps {
	mediaLength: number;
	sourceContent: 'individual review gallery';
	vendorName: Vendor.Decorated['name'];
	isLandscape?: boolean;
}

const ReviewsModalWrapper: FCWithChildren<ModalWrapperProps> = (props) => {
	const { isLandscape, children, ...sharedProps } = props;
	const isOpen = useAtomValue(isReviewsLightboxOpenAtom);
	const closeReviewsLightBox = useSetAtom(closeReviewsLightboxAtom);
	const [, , windowHeight] = useScreenDetails();
	const asideStyle = {
		'--window-height': `${windowHeight}px`,
	} as React.CSSProperties;

	return (
		<ModalPortal>
			<aside style={asideStyle} className={Styles.modalWrapperContainer}>
				<NavigableModal
					hash="reviews-modal"
					isModalOpen={isOpen}
					closeCallback={closeReviewsLightBox}
					type={MODAL_TYPES.CONTAINER}
				>
					{isLandscape ? (
						<Landscape vendorName={sharedProps.vendorName}>
							{children}
						</Landscape>
					) : (
						<Portrait {...sharedProps}>{children}</Portrait>
					)}
				</NavigableModal>
			</aside>
		</ModalPortal>
	);
};

export { ReviewsModalWrapper };
