import React from 'react';
import { FCWithChildren } from 'types/react-extended';
import { useCloseReviewsLightbox } from '../hooks/use-close-reviews-lightbox';
import Footer from './footer';
import Header from './header';

export interface ContentWrapperProps {
	vendorName: Vendor.Decorated['name'];
}

const Portrait: FCWithChildren<ContentWrapperProps> = (props) => {
	const { vendorName, children } = props;
	const closeModal = useCloseReviewsLightbox();

	return (
		<>
			<Header vendorName={vendorName} closeModal={closeModal} />
			{children}
			<Footer />
		</>
	);
};

export { Portrait };
